import { readAndCompressImage } from 'browser-image-resizer';

export interface IProcessedFile {
  file: File;
  reader: FileReader;
  image: HTMLImageElement;
  preview: string;
}
export interface Base64File {
  mimetype: string;
  content: string;
  name?: string;
}
export interface IFileProcessingOptions {
  downscale?: boolean;
}

export class ProcessedFile implements IProcessedFile {
  file: File;
  reader: FileReader;
  image: HTMLImageElement;

  constructor(file: File, reader: FileReader, image: HTMLImageElement) {
    this.file = file;
    this.reader = reader;
    this.image = image;
  }

  asBase64File(): Base64File {
    const parts = this.image.src.split(',');
    return {
      mimetype: parts[0],
      content: parts[1],
    };
  }

  asBlob(): Blob {
    let base64 = this.image.src.split(',')[1];
    return new Blob([Buffer.from(base64, 'base64')]);
  }

  static empty() {
    let file = new File([], 'empty');
    let reader = new FileReader();
    let image = document.createElement('img') as HTMLImageElement;
    return new ProcessedFile(file, reader, image);
  }

  static async fromUrl(url: string, options: IFileProcessingOptions = {}): Promise<ProcessedFile> {
    if (options.downscale === undefined) {
      options.downscale = true;
    }
    let response = await fetch(url);
    let file = (await response.blob()) as File;
    let scaled: Blob;
    if (!options.downscale) {
      scaled = file;
    } else {
      scaled = await readAndCompressImage(file, { maxWidth: 1024, maxHeight: 1024, mimeType: 'image/png' });
    }

    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        var image = new Image();
        image.onload = function () {
          resolve(new ProcessedFile(file, reader, image));
        };
        image.onerror = (e) => {
          reject(e);
        };
        image.src = String(reader.result);
      };
      reader.onerror = (e) => {
        reject(e);
      };
      reader.readAsDataURL(scaled);
    });
  }

  static async fromBlob(blob: Blob, name: string = 'unnamed'): Promise<ProcessedFile> {
    var file = new File([blob], name);
    return ProcessedFile.fromFile(file);
  }

  static async fromFile(file: File, options: IFileProcessingOptions = {}): Promise<ProcessedFile> {
    // TODO width / height is set to 512x512 and should be passed as an argument passing the canvas size
    // also the file is scaled down - original is not saved
    if (options.downscale === undefined) {
      options.downscale = true;
    }
    let scaled: Blob;
    if (!options.downscale) {
      scaled = file;
    } else {
      scaled = await readAndCompressImage(file, { maxWidth: 1024, maxHeight: 1024, mimeType: 'image/png' });
    }

    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        var image = new Image();
        image.onload = function () {
          resolve(new ProcessedFile(file, reader, image));
        };
        image.onerror = (e) => {
          reject(e);
        };
        image.src = String(reader.result);
      };
      reader.onerror = (e) => {
        reject(e);
      };
      reader.readAsDataURL(scaled);
    });
  }

  get path() {
    return '';
  }

  get preview() {
    return this.image.src;
  }
}

export default ProcessedFile;
