import { createContext, ReactNode, useContext, useState } from 'react';
import { setSession } from 'src/auth/utils';
import { DamAsset } from 'src/pages/cloudinary/dam/types';
import { untilTrue } from 'src/utils/timers';

export interface IDamContextValue {
  login(): Promise<void>;
  uploadToDam(url: string, name: string): Promise<void>;
  cloudName: string;
  assets: Array<DamAsset>;
}
const DamContext = createContext<IDamContextValue>({
  login: async () => {},
  uploadToDam,
  cloudName: '',
  assets: [],
});

interface DamContextProviderProps {
  children?: ReactNode;
}

export function useDamContext() {
  const value = useContext(DamContext);

  return value;
}
async function uploadToDam(url: string, name: string) {
  await window.cloudinary.customAction.upload({
    urls: [url],
    name: name,
  });
}
export function DamContextProvider({ children }: DamContextProviderProps) {
  async function login() {
    const success = await untilTrue(() => window.cloudinary.customAction !== undefined, { timeout: 5000, interval: 50 });

    if (!success) {
      throw new Error('custom actions did not load correclty');
    }

    const config = await window.cloudinary.customAction.getConfig();
    const response = await fetch(`${process.env.REACT_APP_APP_SERVER_BASE_PATH}/auth/login/cloudinary/dam?cloud_name=${config.cloudName}`);
    if (!success) {
      throw new Error('cloud is not configured correclty');
    }
    const credentials = await response.json();

    setSession(credentials.token!);
    setValue({ ...value!, ...config });
  }
  const [value, setValue] = useState<IDamContextValue>({
    cloudName: '',
    assets: [],
    uploadToDam,
    login,
  });

  return <DamContext.Provider value={value}>{children}</DamContext.Provider>;
}
