import { Box, CircularProgress, SxProps, Typography } from '@mui/material';

export function CircularProgressOverlay({
  color = 'rgba(255,255,255,0.65)',
  size = '32px',
  label,
  labelStyles,
  backdropStyles,
}: {
  label?: string;
  color?: string;
  size?: string | number;
  labelStyles?: SxProps;
  backdropStyles?: SxProps;
}) {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: color,
        backdropFilter: 'blur(1px)',
        zIndex: 2,
        ...backdropStyles,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          borderRadius: '8px',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          position: 'relative',
          height: '100%',
        }}
      >
        {label && (
          <Typography variant='body2' fontSize='18px' sx={{ marginBottom: 3, ...labelStyles }}>
            {label}
          </Typography>
        )}
        <CircularProgress size={size} />
      </Box>
    </Box>
  );
}
