/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  ImageDescriptionResponse,
  InferEditActionRequest,
  InferEditActionResponse,
  LabsAlignProductsPlatformsRequest,
  LabsPackShotRequest,
  LabsRestyleAndBlendResponse,
  LabsStudioShotRequest,
  LabsTextToImageRequest,
  LabsTextToSubjectRequest,
} from '../models';
import {
  ImageDescriptionResponseFromJSON,
  ImageDescriptionResponseToJSON,
  InferEditActionRequestFromJSON,
  InferEditActionRequestToJSON,
  InferEditActionResponseFromJSON,
  InferEditActionResponseToJSON,
  LabsAlignProductsPlatformsRequestFromJSON,
  LabsAlignProductsPlatformsRequestToJSON,
  LabsPackShotRequestFromJSON,
  LabsPackShotRequestToJSON,
  LabsRestyleAndBlendResponseFromJSON,
  LabsRestyleAndBlendResponseToJSON,
  LabsStudioShotRequestFromJSON,
  LabsStudioShotRequestToJSON,
  LabsTextToImageRequestFromJSON,
  LabsTextToImageRequestToJSON,
  LabsTextToSubjectRequestFromJSON,
  LabsTextToSubjectRequestToJSON,
} from '../models';

export interface LabsApiInferEditActionOperationRequest {
  infer_edit_action_request?: InferEditActionRequest;
}

export interface LabsApiLabsAlignProductsPlatformsOperationRequest {
  labs_align_products_platforms_request?: LabsAlignProductsPlatformsRequest;
}

export interface LabsApiLabsBlendImagesRequest {
  style_image: Blob;
  content_image: Blob;
}

export interface LabsApiLabsImageDescriptionRequest {
  image: Blob;
}

export interface LabsApiLabsManualInpaintRequest {
  image: Blob;
  product_size: number;
  product: string;
  prompt: string;
}

export interface LabsApiLabsPackShotOperationRequest {
  labs_pack_shot_request?: LabsPackShotRequest;
}

export interface LabsApiLabsPedestalsInpaintRequest {
  image: Blob;
  shot_size: string;
  product_size: number;
  product: string;
  prompt: string;
}

export interface LabsApiLabsRestyleAndBlendRequest {
  image: Blob;
  strictness: number;
  prompt: string;
}

export interface LabsApiLabsRestyleImageRequest {
  image: Blob;
  strictness: number;
  prompt: string;
}

export interface LabsApiLabsRestyleSegmentRequest {
  image: Blob;
  strictness: number;
  edit_area: string;
  edit_prompt: string;
}

export interface LabsApiLabsStudioShotOperationRequest {
  labs_studio_shot_request?: LabsStudioShotRequest;
}

export interface LabsApiLabsTextToGraphicDesignRequest {
  labs_text_to_image_request?: LabsTextToImageRequest;
}

export interface LabsApiLabsTextToImageOperationRequest {
  labs_text_to_image_request?: LabsTextToImageRequest;
}

export interface LabsApiLabsTextToSubjectOperationRequest {
  labs_text_to_subject_request?: LabsTextToSubjectRequest;
}

export interface LabsApiLabsVirtualStagingRequest {
  staging_prompt: string;
  avoid_areas: string;
  room: Blob;
}

/**
 *
 */
export class LabsApi extends runtime.BaseAPI {
  /**
   */
  async inferEditActionRaw(
    requestParameters: LabsApiInferEditActionOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<InferEditActionResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/labs/conversational/infer-edit-action`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: InferEditActionRequestToJSON(requestParameters.infer_edit_action_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InferEditActionResponseFromJSON(jsonValue));
  }

  /**
   */
  async inferEditAction(
    infer_edit_action_request?: InferEditActionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<InferEditActionResponse> {
    const response = await this.inferEditActionRaw({ infer_edit_action_request: infer_edit_action_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsAlignProductsPlatformsRaw(
    requestParameters: LabsApiLabsAlignProductsPlatformsOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/labs/general/align-products-platforms`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LabsAlignProductsPlatformsRequestToJSON(requestParameters.labs_align_products_platforms_request),
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsAlignProductsPlatforms(
    labs_align_products_platforms_request?: LabsAlignProductsPlatformsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Blob> {
    const response = await this.labsAlignProductsPlatformsRaw({ labs_align_products_platforms_request: labs_align_products_platforms_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsBlendImagesRaw(
    requestParameters: LabsApiLabsBlendImagesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.style_image === null || requestParameters.style_image === undefined) {
      throw new runtime.RequiredError('style_image', 'Required parameter requestParameters.style_image was null or undefined when calling labsBlendImages.');
    }

    if (requestParameters.content_image === null || requestParameters.content_image === undefined) {
      throw new runtime.RequiredError(
        'content_image',
        'Required parameter requestParameters.content_image was null or undefined when calling labsBlendImages.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.style_image !== undefined) {
      formParams.append('style_image', requestParameters.style_image as any);
    }

    if (requestParameters.content_image !== undefined) {
      formParams.append('content_image', requestParameters.content_image as any);
    }

    const response = await this.request(
      {
        path: `/labs/general/blend-images`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsBlendImages(style_image: Blob, content_image: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
    const response = await this.labsBlendImagesRaw({ style_image: style_image, content_image: content_image }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsImageDescriptionRaw(
    requestParameters: LabsApiLabsImageDescriptionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ImageDescriptionResponse>> {
    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling labsImageDescription.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    const response = await this.request(
      {
        path: `/labs/general/image-description`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ImageDescriptionResponseFromJSON(jsonValue));
  }

  /**
   */
  async labsImageDescription(image: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImageDescriptionResponse> {
    const response = await this.labsImageDescriptionRaw({ image: image }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsManualInpaintRaw(
    requestParameters: LabsApiLabsManualInpaintRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling labsManualInpaint.');
    }

    if (requestParameters.product_size === null || requestParameters.product_size === undefined) {
      throw new runtime.RequiredError(
        'product_size',
        'Required parameter requestParameters.product_size was null or undefined when calling labsManualInpaint.',
      );
    }

    if (requestParameters.product === null || requestParameters.product === undefined) {
      throw new runtime.RequiredError('product', 'Required parameter requestParameters.product was null or undefined when calling labsManualInpaint.');
    }

    if (requestParameters.prompt === null || requestParameters.prompt === undefined) {
      throw new runtime.RequiredError('prompt', 'Required parameter requestParameters.prompt was null or undefined when calling labsManualInpaint.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    if (requestParameters.product_size !== undefined) {
      formParams.append('product_size', requestParameters.product_size as any);
    }

    if (requestParameters.product !== undefined) {
      formParams.append('product', requestParameters.product as any);
    }

    if (requestParameters.prompt !== undefined) {
      formParams.append('prompt', requestParameters.prompt as any);
    }

    const response = await this.request(
      {
        path: `/labs/general/labs-manual-inpaint`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsManualInpaint(
    image: Blob,
    product_size: number,
    product: string,
    prompt: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Blob> {
    const response = await this.labsManualInpaintRaw({ image: image, product_size: product_size, product: product, prompt: prompt }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsPackShotRaw(
    requestParameters: LabsApiLabsPackShotOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/labs/general/pack-shot`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LabsPackShotRequestToJSON(requestParameters.labs_pack_shot_request),
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsPackShot(labs_pack_shot_request?: LabsPackShotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
    const response = await this.labsPackShotRaw({ labs_pack_shot_request: labs_pack_shot_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsPedestalsInpaintRaw(
    requestParameters: LabsApiLabsPedestalsInpaintRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling labsPedestalsInpaint.');
    }

    if (requestParameters.shot_size === null || requestParameters.shot_size === undefined) {
      throw new runtime.RequiredError('shot_size', 'Required parameter requestParameters.shot_size was null or undefined when calling labsPedestalsInpaint.');
    }

    if (requestParameters.product_size === null || requestParameters.product_size === undefined) {
      throw new runtime.RequiredError(
        'product_size',
        'Required parameter requestParameters.product_size was null or undefined when calling labsPedestalsInpaint.',
      );
    }

    if (requestParameters.product === null || requestParameters.product === undefined) {
      throw new runtime.RequiredError('product', 'Required parameter requestParameters.product was null or undefined when calling labsPedestalsInpaint.');
    }

    if (requestParameters.prompt === null || requestParameters.prompt === undefined) {
      throw new runtime.RequiredError('prompt', 'Required parameter requestParameters.prompt was null or undefined when calling labsPedestalsInpaint.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    if (requestParameters.shot_size !== undefined) {
      formParams.append('shot_size', requestParameters.shot_size as any);
    }

    if (requestParameters.product_size !== undefined) {
      formParams.append('product_size', requestParameters.product_size as any);
    }

    if (requestParameters.product !== undefined) {
      formParams.append('product', requestParameters.product as any);
    }

    if (requestParameters.prompt !== undefined) {
      formParams.append('prompt', requestParameters.prompt as any);
    }

    const response = await this.request(
      {
        path: `/labs/general/labs-pedestal-inpaint`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsPedestalsInpaint(
    image: Blob,
    shot_size: string,
    product_size: number,
    product: string,
    prompt: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Blob> {
    const response = await this.labsPedestalsInpaintRaw(
      { image: image, shot_size: shot_size, product_size: product_size, product: product, prompt: prompt },
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async labsRestyleAndBlendRaw(
    requestParameters: LabsApiLabsRestyleAndBlendRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<LabsRestyleAndBlendResponse>> {
    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling labsRestyleAndBlend.');
    }

    if (requestParameters.strictness === null || requestParameters.strictness === undefined) {
      throw new runtime.RequiredError('strictness', 'Required parameter requestParameters.strictness was null or undefined when calling labsRestyleAndBlend.');
    }

    if (requestParameters.prompt === null || requestParameters.prompt === undefined) {
      throw new runtime.RequiredError('prompt', 'Required parameter requestParameters.prompt was null or undefined when calling labsRestyleAndBlend.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    if (requestParameters.strictness !== undefined) {
      formParams.append('strictness', requestParameters.strictness as any);
    }

    if (requestParameters.prompt !== undefined) {
      formParams.append('prompt', requestParameters.prompt as any);
    }

    const response = await this.request(
      {
        path: `/labs/general/restyle-and-blend`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => LabsRestyleAndBlendResponseFromJSON(jsonValue));
  }

  /**
   */
  async labsRestyleAndBlend(
    image: Blob,
    strictness: number,
    prompt: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LabsRestyleAndBlendResponse> {
    const response = await this.labsRestyleAndBlendRaw({ image: image, strictness: strictness, prompt: prompt }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsRestyleImageRaw(
    requestParameters: LabsApiLabsRestyleImageRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling labsRestyleImage.');
    }

    if (requestParameters.strictness === null || requestParameters.strictness === undefined) {
      throw new runtime.RequiredError('strictness', 'Required parameter requestParameters.strictness was null or undefined when calling labsRestyleImage.');
    }

    if (requestParameters.prompt === null || requestParameters.prompt === undefined) {
      throw new runtime.RequiredError('prompt', 'Required parameter requestParameters.prompt was null or undefined when calling labsRestyleImage.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    if (requestParameters.strictness !== undefined) {
      formParams.append('strictness', requestParameters.strictness as any);
    }

    if (requestParameters.prompt !== undefined) {
      formParams.append('prompt', requestParameters.prompt as any);
    }

    const response = await this.request(
      {
        path: `/labs/general/restyle-image`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsRestyleImage(image: Blob, strictness: number, prompt: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
    const response = await this.labsRestyleImageRaw({ image: image, strictness: strictness, prompt: prompt }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsRestyleSegmentRaw(
    requestParameters: LabsApiLabsRestyleSegmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling labsRestyleSegment.');
    }

    if (requestParameters.strictness === null || requestParameters.strictness === undefined) {
      throw new runtime.RequiredError('strictness', 'Required parameter requestParameters.strictness was null or undefined when calling labsRestyleSegment.');
    }

    if (requestParameters.edit_area === null || requestParameters.edit_area === undefined) {
      throw new runtime.RequiredError('edit_area', 'Required parameter requestParameters.edit_area was null or undefined when calling labsRestyleSegment.');
    }

    if (requestParameters.edit_prompt === null || requestParameters.edit_prompt === undefined) {
      throw new runtime.RequiredError('edit_prompt', 'Required parameter requestParameters.edit_prompt was null or undefined when calling labsRestyleSegment.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    if (requestParameters.strictness !== undefined) {
      formParams.append('strictness', requestParameters.strictness as any);
    }

    if (requestParameters.edit_area !== undefined) {
      formParams.append('edit_area', requestParameters.edit_area as any);
    }

    if (requestParameters.edit_prompt !== undefined) {
      formParams.append('edit_prompt', requestParameters.edit_prompt as any);
    }

    const response = await this.request(
      {
        path: `/labs/conversational/labs-restyle-segment`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsRestyleSegment(
    image: Blob,
    strictness: number,
    edit_area: string,
    edit_prompt: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Blob> {
    const response = await this.labsRestyleSegmentRaw({ image: image, strictness: strictness, edit_area: edit_area, edit_prompt: edit_prompt }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsStudioShotRaw(
    requestParameters: LabsApiLabsStudioShotOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/labs/general/studio-shot`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LabsStudioShotRequestToJSON(requestParameters.labs_studio_shot_request),
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsStudioShot(labs_studio_shot_request?: LabsStudioShotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
    const response = await this.labsStudioShotRaw({ labs_studio_shot_request: labs_studio_shot_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsTextToGraphicDesignRaw(
    requestParameters: LabsApiLabsTextToGraphicDesignRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/labs/general/text-to-graphic-design`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LabsTextToImageRequestToJSON(requestParameters.labs_text_to_image_request),
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsTextToGraphicDesign(
    labs_text_to_image_request?: LabsTextToImageRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Blob> {
    const response = await this.labsTextToGraphicDesignRaw({ labs_text_to_image_request: labs_text_to_image_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsTextToImageRaw(
    requestParameters: LabsApiLabsTextToImageOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/labs/general/text-to-image`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LabsTextToImageRequestToJSON(requestParameters.labs_text_to_image_request),
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsTextToImage(labs_text_to_image_request?: LabsTextToImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
    const response = await this.labsTextToImageRaw({ labs_text_to_image_request: labs_text_to_image_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsTextToSubjectRaw(
    requestParameters: LabsApiLabsTextToSubjectOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/labs/general/text-to-subject`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LabsTextToSubjectRequestToJSON(requestParameters.labs_text_to_subject_request),
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsTextToSubject(labs_text_to_subject_request?: LabsTextToSubjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
    const response = await this.labsTextToSubjectRaw({ labs_text_to_subject_request: labs_text_to_subject_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsVirtualStagingRaw(
    requestParameters: LabsApiLabsVirtualStagingRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.staging_prompt === null || requestParameters.staging_prompt === undefined) {
      throw new runtime.RequiredError(
        'staging_prompt',
        'Required parameter requestParameters.staging_prompt was null or undefined when calling labsVirtualStaging.',
      );
    }

    if (requestParameters.avoid_areas === null || requestParameters.avoid_areas === undefined) {
      throw new runtime.RequiredError('avoid_areas', 'Required parameter requestParameters.avoid_areas was null or undefined when calling labsVirtualStaging.');
    }

    if (requestParameters.room === null || requestParameters.room === undefined) {
      throw new runtime.RequiredError('room', 'Required parameter requestParameters.room was null or undefined when calling labsVirtualStaging.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.staging_prompt !== undefined) {
      formParams.append('staging_prompt', requestParameters.staging_prompt as any);
    }

    if (requestParameters.avoid_areas !== undefined) {
      formParams.append('avoid_areas', requestParameters.avoid_areas as any);
    }

    if (requestParameters.room !== undefined) {
      formParams.append('room', requestParameters.room as any);
    }

    const response = await this.request(
      {
        path: `/labs/general/virtual-staging`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsVirtualStaging(staging_prompt: string, avoid_areas: string, room: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
    const response = await this.labsVirtualStagingRaw({ staging_prompt: staging_prompt, avoid_areas: avoid_areas, room: room }, initOverrides);
    return await response.value();
  }
}
