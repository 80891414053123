// @mui
import { Stack, InputAdornment, TextField, MenuItem, Box, CircularProgress } from '@mui/material';
// components
import Iconify from '../iconify';
import { OriginLabels, originTypesEnum, OriginTypesEnum } from './AssetUtils';
import { AssetOriginEnum } from 'src/generated/app_server_sdk';

// ----------------------------------------------------------------------

type Props = {
  filterName: string;
  filterOrigin: OriginTypesEnum;
  optionsOrigin: Array<OriginTypesEnum>;
  onFilterName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterOrigin: (event: React.ChangeEvent<HTMLInputElement>, originTypes: Array<OriginTypesEnum>) => void;
  isSearching: boolean;
};

export function AssetFilterToolbar({ filterName, filterOrigin, optionsOrigin, onFilterName, onFilterOrigin, isSearching }: Props) {
  const handleOriginCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const origin = value as unknown as AssetOriginEnum;
    if (value === originTypesEnum.All) {
      return onFilterOrigin(event, optionsOrigin);
    }
    return onFilterOrigin(event, [origin]);
  };

  return (
    <Stack spacing={2} alignItems='center' direction={'row'} sx={{ height: '2rem' }}>
      <Box sx={{ flex: 3, justifyContent: 'end', display: 'flex' }}>
        {filterOrigin !== AssetOriginEnum.UserProduct && (
          <TextField
            fullWidth
            size='small'
            select
            value={filterOrigin}
            onChange={handleOriginCheck}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    maxHeight: 200,
                  },
                },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
            }}
          >
            {optionsOrigin.map((option) => (
              <MenuItem
                key={option}
                value={option}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                  '&:first-of-type': { mt: 0 },
                  '&:last-of-type': { mb: 0 },
                }}
              >
                {OriginLabels[option]}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>
      <Box sx={{ flex: 1 }}>
        <TextField
          fullWidth
          sx={{ justifySelf: 'flex-end', width: '220px' }}
          size='small'
          value={filterName}
          onChange={onFilterName}
          placeholder='Search...'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Iconify icon='eva:search-fill' sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
            endAdornment: isSearching && (
              <InputAdornment position='end'>
                <CircularProgress size={20} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Stack>
  );
}
