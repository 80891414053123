import { Box, Chip } from '@mui/material';
import { Outlet } from 'react-router-dom';
import DesktopHeader from 'src/components/editor/DesktopHeader';
import MobileHeader from 'src/components/editor/MobileHeader';
import useResponsive from 'src/hooks/useResponsive';

export default function CADLayout() {
  const isDesktop = useResponsive('up', 'md');
  return (
    <>
      {isDesktop ? (
        <Box pb={6} height='100%'>
          <DesktopHeader />

          <Outlet />
          <Chip
            label={process.env.REACT_APP_ENV}
            color='error'
            size={isDesktop ? 'medium' : 'small'}
            sx={{
              position: 'fixed',
              bottom: isDesktop ? '10px' : '40px',
              fontSize: !isDesktop ? '8px' : undefined,
              right: '10px',
              display: process.env.REACT_APP_ENV !== 'production' ? undefined : 'none',
            }}
          />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <MobileHeader />
          <Box sx={{ overflow: 'auto' }}>
            <Outlet />
          </Box>
        </Box>
      )}
    </>
  );
}
