/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  CreateSceneRequestPayload,
  GenerateScenePreviewResponse,
  PromptRefinmentRequest,
  PromptRefinmentResponse,
  Scene,
  SuggestedScenesResponse,
} from '../models';
import {
  CreateSceneRequestPayloadFromJSON,
  CreateSceneRequestPayloadToJSON,
  GenerateScenePreviewResponseFromJSON,
  GenerateScenePreviewResponseToJSON,
  PromptRefinmentRequestFromJSON,
  PromptRefinmentRequestToJSON,
  PromptRefinmentResponseFromJSON,
  PromptRefinmentResponseToJSON,
  SceneFromJSON,
  SceneToJSON,
  SuggestedScenesResponseFromJSON,
  SuggestedScenesResponseToJSON,
} from '../models';

export interface ScenesApiCreateSceneRequest {
  create_scene_request_payload?: CreateSceneRequestPayload;
}

export interface ScenesApiEditorsPickRequest {
  max_results?: number;
}

export interface ScenesApiGenerateScenePreviewRequest {
  placement_canvas_content: string;
  sample_asset_id: string;
  scene_name: string;
  scene_id?: string;
  negative_prompt?: string;
  positive_prompt?: string;
  inspiration2?: Blob;
  inspiration1?: Blob;
}

export interface ScenesApiPromptRefinmentOperationRequest {
  prompt_refinment_request?: PromptRefinmentRequest;
}

export interface ScenesApiSceneCategoriesRequest {
  size?: string;
}

export interface ScenesApiSceneSearchRequest {
  product_id?: string;
  category?: string;
}

export interface ScenesApiScenesSearchScenesByTextGetRequest {
  search_term: string;
}

export interface ScenesApiScenesShowIdGetRequest {
  id: string;
}

export interface ScenesApiSuggestedScenesRequest {
  product_id: string;
  max_results?: number;
}

/**
 *
 */
export class ScenesApi extends runtime.BaseAPI {
  /**
   */
  async createSceneRaw(
    requestParameters: ScenesApiCreateSceneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Scene>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/scenes/create`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateSceneRequestPayloadToJSON(requestParameters.create_scene_request_payload),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SceneFromJSON(jsonValue));
  }

  /**
   */
  async createScene(create_scene_request_payload?: CreateSceneRequestPayload, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Scene> {
    const response = await this.createSceneRaw({ create_scene_request_payload: create_scene_request_payload }, initOverrides);
    return await response.value();
  }

  /**
   */
  async editorsPickRaw(
    requestParameters: ScenesApiEditorsPickRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SuggestedScenesResponse>> {
    const queryParameters: any = {};

    if (requestParameters.max_results !== undefined) {
      queryParameters['max_results'] = requestParameters.max_results;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/scenes/editors-pick`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SuggestedScenesResponseFromJSON(jsonValue));
  }

  /**
   */
  async editorsPick(max_results?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuggestedScenesResponse> {
    const response = await this.editorsPickRaw({ max_results: max_results }, initOverrides);
    return await response.value();
  }

  /**
   */
  async generateScenePreviewRaw(
    requestParameters: ScenesApiGenerateScenePreviewRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GenerateScenePreviewResponse>> {
    if (requestParameters.placement_canvas_content === null || requestParameters.placement_canvas_content === undefined) {
      throw new runtime.RequiredError(
        'placement_canvas_content',
        'Required parameter requestParameters.placement_canvas_content was null or undefined when calling generateScenePreview.',
      );
    }

    if (requestParameters.sample_asset_id === null || requestParameters.sample_asset_id === undefined) {
      throw new runtime.RequiredError(
        'sample_asset_id',
        'Required parameter requestParameters.sample_asset_id was null or undefined when calling generateScenePreview.',
      );
    }

    if (requestParameters.scene_name === null || requestParameters.scene_name === undefined) {
      throw new runtime.RequiredError('scene_name', 'Required parameter requestParameters.scene_name was null or undefined when calling generateScenePreview.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.scene_id !== undefined) {
      formParams.append('sceneId', requestParameters.scene_id as any);
    }

    if (requestParameters.placement_canvas_content !== undefined) {
      formParams.append('placementCanvasContent', requestParameters.placement_canvas_content as any);
    }

    if (requestParameters.sample_asset_id !== undefined) {
      formParams.append('sampleAssetId', requestParameters.sample_asset_id as any);
    }

    if (requestParameters.negative_prompt !== undefined) {
      formParams.append('negativePrompt', requestParameters.negative_prompt as any);
    }

    if (requestParameters.positive_prompt !== undefined) {
      formParams.append('positivePrompt', requestParameters.positive_prompt as any);
    }

    if (requestParameters.scene_name !== undefined) {
      formParams.append('sceneName', requestParameters.scene_name as any);
    }

    if (requestParameters.inspiration2 !== undefined) {
      formParams.append('inspiration2', requestParameters.inspiration2 as any);
    }

    if (requestParameters.inspiration1 !== undefined) {
      formParams.append('inspiration1', requestParameters.inspiration1 as any);
    }

    const response = await this.request(
      {
        path: `/scenes/generate-scene-preview`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GenerateScenePreviewResponseFromJSON(jsonValue));
  }

  /**
   */
  async generateScenePreview(
    placement_canvas_content: string,
    sample_asset_id: string,
    scene_name: string,
    scene_id?: string,
    negative_prompt?: string,
    positive_prompt?: string,
    inspiration2?: Blob,
    inspiration1?: Blob,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GenerateScenePreviewResponse> {
    const response = await this.generateScenePreviewRaw(
      {
        placement_canvas_content: placement_canvas_content,
        sample_asset_id: sample_asset_id,
        scene_name: scene_name,
        scene_id: scene_id,
        negative_prompt: negative_prompt,
        positive_prompt: positive_prompt,
        inspiration2: inspiration2,
        inspiration1: inspiration1,
      },
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async promptRefinmentRaw(
    requestParameters: ScenesApiPromptRefinmentOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PromptRefinmentResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/scenes/prompt-refinment`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PromptRefinmentRequestToJSON(requestParameters.prompt_refinment_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PromptRefinmentResponseFromJSON(jsonValue));
  }

  /**
   */
  async promptRefinment(
    prompt_refinment_request?: PromptRefinmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PromptRefinmentResponse> {
    const response = await this.promptRefinmentRaw({ prompt_refinment_request: prompt_refinment_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async sceneCategoriesRaw(
    requestParameters: ScenesApiSceneCategoriesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SuggestedScenesResponse>> {
    const queryParameters: any = {};

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/scenes/categories`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SuggestedScenesResponseFromJSON(jsonValue));
  }

  /**
   */
  async sceneCategories(size?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuggestedScenesResponse> {
    const response = await this.sceneCategoriesRaw({ size: size }, initOverrides);
    return await response.value();
  }

  /**
   */
  async sceneSearchRaw(
    requestParameters: ScenesApiSceneSearchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SuggestedScenesResponse>> {
    const queryParameters: any = {};

    if (requestParameters.product_id !== undefined) {
      queryParameters['product_id'] = requestParameters.product_id;
    }

    if (requestParameters.category !== undefined) {
      queryParameters['category'] = requestParameters.category;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/scenes/search`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SuggestedScenesResponseFromJSON(jsonValue));
  }

  /**
   */
  async sceneSearch(product_id?: string, category?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuggestedScenesResponse> {
    const response = await this.sceneSearchRaw({ product_id: product_id, category: category }, initOverrides);
    return await response.value();
  }

  /**
   */
  async scenesSearchScenesByTextGetRaw(
    requestParameters: ScenesApiScenesSearchScenesByTextGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SuggestedScenesResponse>> {
    if (requestParameters.search_term === null || requestParameters.search_term === undefined) {
      throw new runtime.RequiredError(
        'search_term',
        'Required parameter requestParameters.search_term was null or undefined when calling scenesSearchScenesByTextGet.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.search_term !== undefined) {
      queryParameters['search_term'] = requestParameters.search_term;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/scenes/search-scenes-by-text`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SuggestedScenesResponseFromJSON(jsonValue));
  }

  /**
   */
  async scenesSearchScenesByTextGet(search_term: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuggestedScenesResponse> {
    const response = await this.scenesSearchScenesByTextGetRaw({ search_term: search_term }, initOverrides);
    return await response.value();
  }

  /**
   */
  async scenesShowIdGetRaw(
    requestParameters: ScenesApiScenesShowIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Scene>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling scenesShowIdGet.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/scenes/show/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SceneFromJSON(jsonValue));
  }

  /**
   */
  async scenesShowIdGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Scene> {
    const response = await this.scenesShowIdGetRaw({ id: id }, initOverrides);
    return await response.value();
  }

  /**
   */
  async suggestedScenesRaw(
    requestParameters: ScenesApiSuggestedScenesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SuggestedScenesResponse>> {
    if (requestParameters.product_id === null || requestParameters.product_id === undefined) {
      throw new runtime.RequiredError('product_id', 'Required parameter requestParameters.product_id was null or undefined when calling suggestedScenes.');
    }

    const queryParameters: any = {};

    if (requestParameters.max_results !== undefined) {
      queryParameters['max_results'] = requestParameters.max_results;
    }

    if (requestParameters.product_id !== undefined) {
      queryParameters['product_id'] = requestParameters.product_id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/scenes/suggested-scenes`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SuggestedScenesResponseFromJSON(jsonValue));
  }

  /**
   */
  async suggestedScenes(
    product_id: string,
    max_results?: number,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SuggestedScenesResponse> {
    const response = await this.suggestedScenesRaw({ product_id: product_id, max_results: max_results }, initOverrides);
    return await response.value();
  }

  /**
   */
  async userGeneratedScenesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuggestedScenesResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/scenes/user-generated-scenes`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SuggestedScenesResponseFromJSON(jsonValue));
  }

  /**
   */
  async userGeneratedScenes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuggestedScenesResponse> {
    const response = await this.userGeneratedScenesRaw(initOverrides);
    return await response.value();
  }
}
