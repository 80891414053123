import { Helmet } from 'react-helmet-async';
// sections
import { useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { m } from 'framer-motion';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';

import { useNavigate } from 'react-router';
import { setSession } from 'src/auth/utils';
import { MotionContainer, varBounce } from 'src/components/animate';
import analytics from 'src/utils/analytics';
import appServer from 'src/utils/appServer';
import Login from '../../sections/auth/Login';

// ----------------------------------------------------------------------

export default function LoginPage() {
  let navigate = useNavigate();

  let [blocked, setBlocked] = useState(false);
  let [walledgarden, setWalledgarden] = useState('');
  let [blockedAnimation, setBlockedAnimation] = useState();
  useEffect(() => {
    let url = new URL(window.location.href);
    let token = url.searchParams.get('token') || localStorage.getItem('accessToken');
    if (token) {
      setSession(token);
      appServer.utilsApi
        .identity()
        .then((user) => {
          analytics.track({ event_name: 'logged_in' });
          if (user.is_new) {
            analytics.track({ event_name: 'signed_up' });
          }
          analytics.flushBufferIfNeeded(true);
          setTimeout(() => {
            navigate('/');
            navigate(0);
          }, 100);
        })
        .catch((_) => {});
    } else {
      appServer.utilsApi
        .walledgarden()
        .then((resp) => {
          setBlocked(resp.blocked);
          if (resp.blocked) {
            fetch('https://res.cloudinary.com/ft-bounty/raw/upload/v1692693925/app-materials/blocked.json').then(async (resp) => {
              let animation = await resp.json();
              setBlockedAnimation(animation);
            });
          }
          if (resp.walledgarden) {
            setWalledgarden(resp.walledgarden);
          }
        })
        .catch((_e) => {});
    }

    // store token
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title> Login | Final Touch</title>
      </Helmet>
      {blocked ? (
        <Grid container spacing={0} direction='column' alignItems='center' justifyContent='center' sx={{ minHeight: '100vh' }}>
          <Grid item xs={3} p={3}>
            <MotionContainer>
              <m.div variants={varBounce().in}>
                <img src='https://res.cloudinary.com/ft-bounty/image/upload/w_48,w_48/v1684407931/app-materials/logo-icon.png' alt='' />
              </m.div>
            </MotionContainer>
          </Grid>
          <Grid item xs={3} p={2}>
            <MotionContainer>
              <Typography variant='h3' paragraph textAlign={'justify'}>
                Final touch can't be open inside {walledgarden}. please open the link in your web browser
              </Typography>

              <div>
                <Typography variant='h3' textAlign={'center'} color={'primary'}>
                  <input
                    type='text'
                    value={window.location.href}
                    style={{
                      outline: 'none',
                      border: '0px black',
                      width: '100%',
                      fontSize: '24px',
                      fontFamily: 'Raleway, sans-serif',
                      color: '#e11374',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                    onClick={(e) => {
                      e.currentTarget.select();
                    }}
                    onChange={() => {}}
                  />
                </Typography>
              </div>
              {blockedAnimation && <Lottie loop={false} animationData={blockedAnimation} play style={{ width: 240, height: 240 }} />}
            </MotionContainer>
          </Grid>
        </Grid>
      ) : (
        <Login />
      )}
    </>
  );
}
