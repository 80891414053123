export async function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function untilTrue(predicate: () => boolean, options: { timeout: number; interval?: number; error_message?: string }): Promise<boolean> {
  if (!options.interval) {
    options.interval = 100;
  }
  if (!options.error_message) {
    options.error_message = 'Timeout Exceeded';
  }
  const startTime = Date.now();
  while (!predicate()) {
    if (Date.now() - startTime >= options.timeout) {
      return false;
    }
    await timeout(options.interval);
  }
  return true;
}
