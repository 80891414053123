/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AssetUpdateRequestPayload
 */
export interface AssetUpdateRequestPayload {
  /**
   *
   * @type {string}
   * @memberof AssetUpdateRequestPayload
   */
  product_id?: string;
  /**
   *
   * @type {string}
   * @memberof AssetUpdateRequestPayload
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof AssetUpdateRequestPayload
   */
  custom_size?: number;
  /**
   *
   * @type {object}
   * @memberof AssetUpdateRequestPayload
   */
  transparent_image?: object;
}

/**
 * Check if a given object implements the AssetUpdateRequestPayload interface.
 */
export function instanceOfAssetUpdateRequestPayload(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function AssetUpdateRequestPayloadFromJSON(json: any): AssetUpdateRequestPayload {
  return AssetUpdateRequestPayloadFromJSONTyped(json, false);
}

export function AssetUpdateRequestPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetUpdateRequestPayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    product_id: !exists(json, 'product_id') ? undefined : json['product_id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    custom_size: !exists(json, 'custom_size') ? undefined : json['custom_size'],
    transparent_image: !exists(json, 'transparent_image') ? undefined : json['transparent_image'],
  };
}

export function AssetUpdateRequestPayloadToJSON(value?: AssetUpdateRequestPayload | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    product_id: value.product_id,
    name: value.name,
    custom_size: value.custom_size,
    transparent_image: value.transparent_image,
  };
}
