import { AssetOriginEnum } from 'src/generated/app_server_sdk';

export const originTypesEnum = {
  All: 'All Creations',
  ...AssetOriginEnum,
} as const;

export type OriginTypesEnum = (typeof originTypesEnum)[keyof typeof originTypesEnum];

// ----------------------------------------------------------------------
// Fix for the label of the origin filter
// ----------------------------------------------------------------------
export const OriginLabels = Object.fromEntries(
  Object.values(originTypesEnum).map((origin) => {
    const originType = origin as AssetOriginEnum;
    if (originType === AssetOriginEnum.GeneratedProp) {
      return [origin, 'Prop Generator'];
    }
    return [origin, origin.replace(/_/g, ' ')];
  }),
);
