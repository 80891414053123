import { createContext, useCallback, useEffect, useReducer } from 'react';

import { isMobile } from 'react-device-detect';
import { useIntercom } from 'react-use-intercom';
import analytics from 'src/utils/analytics';
import appServer from 'src/utils/appServer';
import { useLogger } from 'src/utils/Logger';
import { ActionMapType, AuthStateType, AuthUserType, JWTContextType } from './types';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const logger = useLogger();
  const { boot, trackEvent } = useIntercom();
  analytics.appendIntercomSink(trackEvent);

  const initialize = useCallback(async () => {
    try {
      const accessToken = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : '';
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        try {
          const user = await appServer.utilsApi.identity();
          const inIframe = () => {
            return window.self !== window.top;
          };
          const skipIntercomBoot = inIframe() || isMobile;
          if (skipIntercomBoot) {
            logger.debug('do not display Intercom in iframe or mobile');
          } else {
            boot({
              email: user.email,
              userId: user.id,
            });
          }

          analytics.heap.identify(user.id);
          analytics.heap.addUserProperties({ id: user.id, email: user.email, name: user.name });

          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } catch (_) {
          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (_error) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [boot, logger]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = async (_email: string, _password: string) => {
    throw new Error('not supported');
    // const { accessToken, user } = response.data;

    // setSession(accessToken);

    // dispatch({
    //   type: Types.LOGIN,
    //   payload: {
    //     user,
    //   },
    // });
  };

  // REGISTER
  const register = async (_email: string, _password: string, _firstName: string, _lastName: string) => {
    throw new Error('not supported');

    // const { accessToken, user } = response.data;

    // localStorage.setItem('accessToken', accessToken);

    // dispatch({
    //   type: Types.REGISTER,
    //   payload: {
    //     user,
    //   },
    // });
  };

  // LOGOUT
  const logout = async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        loginWithGoogle: () => {
          let windowLocation = new URL(window.location.href);
          let url = `${process.env.REACT_APP_APP_SERVER_BASE_PATH}/auth/login/google?return_to=${encodeURI(window.location.href)}&with_token=1`;
          if (windowLocation.searchParams.has('pc')) {
            url += `&pc=${windowLocation.searchParams.get('pc')}`;
          }
          window.location.href = url;
        },
        loginWithCloudinary: () => {
          let windowLocation = new URL(window.location.href);
          let url = `${process.env.REACT_APP_APP_SERVER_BASE_PATH}/auth/login/cloudinary?return_to=${encodeURI(window.location.href)}&with_token=1`;
          if (windowLocation.searchParams.has('pc')) {
            url += `&pc=${windowLocation.searchParams.get('pc')}`;
          }
          window.location.href = url;
        },
        loginWithLinkedin: () => {
          let windowLocation = new URL(window.location.href);
          let url = `${process.env.REACT_APP_APP_SERVER_BASE_PATH}/auth/login/linkedin?return_to=${encodeURI(window.location.href)}&with_token=1`;
          if (windowLocation.searchParams.has('pc')) {
            url += `&pc=${windowLocation.searchParams.get('pc')}`;
          }
          window.location.href = url;
        },

        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
